import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "me-4" }
const _hoisted_5 = {
  type: "button",
  class: "btn btn-flex btn-light btn-active-primary fw-bolder",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-start",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_6 = { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_customers_export_modal"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = { class: "svg-icon svg-icon-2" }
const _hoisted_12 = { class: "card-body pt-0" }
const _hoisted_13 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_14 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_15 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_16 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "svg-icon svg-icon-3" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_IveFilter = _resolveComponent("IveFilter")!
  const _component_vue3_json_excel = _resolveComponent("vue3-json-excel")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'ive'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen031.svg" })
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.translate('filter')), 1)
              ]),
              _createVNode(_component_IveFilter, {
                onIveTypeSelected: _ctx.onTypeChange,
                onReset: _ctx.onReset
              }, null, 8, ["onIveTypeSelected", "onReset"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_vue3_json_excel, {
                "json-data": _ctx.excelData,
                fields: _ctx.excelHeader,
                worksheet: "Ive WorkSheet",
                name: "Ive-area.xls"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", _hoisted_9, [
                    _createElementVNode("span", _hoisted_10, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr078.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate('exportIveArea')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["json-data", "fields"]),
              (_ctx.can('create', 'ive'))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn btn-primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo('ive-areas-creating')))
                  }, [
                    _createElementVNode("span", _hoisted_11, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Plus.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate('addNewIVEAreaValue')), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_Datatable, {
            "table-data": _ctx.tableData,
            "table-header": _ctx.tableHeader,
            loading: _ctx.loading,
            "rows-per-page": 10,
            total: _ctx.totalItems,
            "enable-items-per-page-dropdown": false,
            onCurrentChange: _ctx.pageChanged
          }, {
            "cell-id": _withCtx(({ row: area }) => [
              _createTextVNode(_toDisplayString(area.id), 1)
            ]),
            "cell-titleEn": _withCtx(({ row: area }) => [
              _createTextVNode(_toDisplayString(area.titleEn), 1)
            ]),
            "cell-titleAr": _withCtx(({ row: area }) => [
              _createTextVNode(_toDisplayString(area.titleAr), 1)
            ]),
            "cell-price": _withCtx(({ row: area }) => [
              _createTextVNode(_toDisplayString(area.price), 1)
            ]),
            "cell-isSeaView": _withCtx(({ row: area }) => [
              (area.isSeaView)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.translate('yes')), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.translate('no')), 1))
            ]),
            "cell-updatedAt": _withCtx(({ row: area }) => [
              _createTextVNode(_toDisplayString(area.updatedAt), 1)
            ]),
            "cell-basePrice": _withCtx(({ row: area }) => [
              _createTextVNode(_toDisplayString(area.basePrice), 1)
            ]),
            "cell-baseSize": _withCtx(({ row: area }) => [
              _createTextVNode(_toDisplayString(area.baseSize), 1)
            ]),
            "cell-isActive": _withCtx(({ row: area }) => [
              (area.isActive)
                ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.translate('active')), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.translate('inactive')), 1))
            ]),
            "cell-actions": _withCtx(({ row: area }) => [
              _createElementVNode("a", {
                href: "#",
                class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1",
                onClick: _withModifiers(($event: any) => (_ctx.editArea(area.id)), ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_18, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/art/art005.svg" })
                ])
              ], 8, _hoisted_17),
              (_ctx.can('remove', 'ive'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: "#",
                    class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm",
                    onClick: _withModifiers(($event: any) => (_ctx.toggleStatusIveArea(area)), ["prevent"])
                  }, [
                    _createElementVNode("span", _hoisted_20, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen019.svg" })
                    ])
                  ], 8, _hoisted_19))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["table-data", "table-header", "loading", "total", "onCurrentChange"])
        ])
      ]))
    : _createCommentVNode("", true)
}